<template>
  <form class="form sh-auth-form" novalidate="novalidate" id="sh_forgot_form">
    <div class="auth-title">
      {{ $t("AUTH.CHECK_INBOX") }}</div>

    <div class="auth-subtitle mb-6">
      {{ $t("AUTH.CHECK_INBOX_INFO") }}</div>
    <div class="pb-lg-0 mb-5">
      <div class="row">
        <div class="col">
          <router-link to="/auth/forgot-password">
            <base-button block>
              {{ $t('AUTH.TRY_AGAIN') }}</base-button>
          </router-link>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'CheckInbox',
  data() {
    return {
      model: {},
    };
  },
};
</script>
